body {
    margin: 0;
    padding: 0;
}



.loader {

    overflow: hidden;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    z-index: 100000;

}

.loader__element {
    border-radius: 100%;
    border: 2px solid #005fc1;
    margin: calc(5px * 2);
}

.loader__element:nth-child(1) {
    animation: preloader .6s ease-in-out alternate infinite;
}

.loader__element:nth-child(2) {
    animation: preloader .6s ease-in-out alternate .2s infinite;
}

.loader__element:nth-child(3) {
    animation: preloader .6s ease-in-out alternate .4s infinite;
}

@keyframes preloader {
    100% {
        transform: scale(2);
    }
}

.spinner {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background: transparent;
    border-radius: 50%;
    color: #005fc1;
}

.spinner:hover {
    cursor: wait;
}

.spinner:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 100%;
    height: 100%;
    border: 4px solid transparent;
    border-top: 4px dotted #005fc1;
    border-right: 4px solid #005fc1;
    border-radius: 50%;
    animation: animate 0.5s linear infinite;
}



@keyframes animate {
    0% {
        transform: rotate(0deg);
    }



    100% {
        transform: rotate(360deg);
    }
}