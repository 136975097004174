* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.input-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    padding: 9px 5px;
    gap: 20px;
    height: 100%;
    width: 100%;
    user-select: none;
}

.search-title h3{
    padding-left: 10px;
    margin: 10px 0;
}

.input-search {
    padding: 6px;
    border: 1px solid #005fc1;
    border-radius: 5px;
    outline: none;
width: 300px;
text-align: center;
    cursor: pointer;


}
.react-datepicker__input-container input {
    padding: 6px 44px;
    border: 1px solid  #005fc1;
    border-radius: 5px;
}
.react-datepicker-popper {
    z-index: 5;
}
.room {
    width: 300px;
text-align: center;
    padding: 6px;
    border: 1px solid #005fc1;
    background-color: transparent;
    border-radius: 5px;
    position: relative;
}
.room span{
    margin:0 5px;
    color: #005fc1;
    font-weight: 700;
   
}
.btn-se {
    border: 1px solid #ffffff;
    outline: none;
    border-radius: 5px;
    background: #005fc1;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding: 6px 10px;

}

.btn-se:hover {

    background-color: #fff;
    color: #005fc1;
    border: 1px solid #005fc1;
    font-family: 600;
}


.options {
    z-index: 5;
    position: absolute;
    top: 155px;
    margin-left: 35px;
    background-color: white;
    color: gray;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
}

.optionItem {
    width: 200px;
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.optionCounter {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    color: black;
}

.optionCounterButton {
    width: 30px;
    height: 30px;
    border: 1px solid #005fc1;
    color: #005fc1;
    font-size: 18px;
    cursor: pointer;
    background-color: white;
}
.optionCounterNumber {
    width:25px;
    text-align: center;
}
.optionCounterButton:disabled {
    cursor: not-allowed;
}
