.menu-res{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
    position: sticky;
    top:0;
   margin-bottom: 10px;
    background-color: #fff;
    border-bottom: 1px solid rgb(192, 192, 192);
    background-color: #f4f3f7;
}
.title-res{
    padding: 7px 18px;
    text-transform: uppercase;
   
    letter-spacing: 1px;
}
.menu-img{
    width: 100%;
    height: 130px;
    object-fit: contain;
}
.res-con{
    background-color: #f4f3f7;
}
.price{
    font-weight: bold;
    font-size: 20px;
}
.menu-p{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.badge {
    display: inline-block;
    padding: -3.65em .65em;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: super;
    border-radius: 0.25rem;
}
@media only screen and (max-width: 1023px) {
    .title-res{
       margin-left: 50px;
        
    }
}