.m-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    padding: 3.5px 10px;
    letter-spacing: 1px;
    margin-top: 7px;
}

.btn-submit {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination {
    position: fixed;
    bottom: 0;
    margin-left: 50%;
}
@media only screen and (max-width: 1023px) {.m-title{
    margin-left: 25px;
}}