.table-lable-rep {

    padding: 10px 7px !important;

}

.table-rep {
    padding: 10px !important;

}

.titlerep{
    text-transform: uppercase;
    padding: 3.5px 10px;
    letter-spacing: 1px;
    margin-top: 7px;
}
