.st-title{
    text-transform: uppercase;
    padding: 3px 10px;
    letter-spacing: 1px;
    margin-top: 7px;
}
.btn-add {

    background-color: #005fc1;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
}
@media only screen and (max-width: 1023px) {
    .st-title{margin-left: 25px;}
}